#homepage-products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-gallery-frame {
  width: 80vw;
  padding: 10px;
}

.image-gallery-frame p {
	font-size: 14px;
	margin-top: 20px;
	color: #aaa;
}

.product-details {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}

.product-info .product-info-text {
	font-size: 16px;
	padding: 0 20px;
}
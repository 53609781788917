#contact-section {
  text-align: center;
}

#contact-description {
  margin: 30px 0;
}

#contact-location-header,
#contact-hours {
  margin-top: 40px;
}

#contact-email {
  margin-top: 50px;
}

.contact-text {
  font-size: 12px;
}

#products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#product-details {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}

#image-gallery-frame {
  width: 80vw;
  padding: 10px;
}

#product-info {
  width: 280px;
  margin-bottom: 15px;
}

.modal-frame {
  width: 90vw;
  max-height: 90vh;
  background: #f0f0f0;
  border: 1px solid #d9d7d7;
  position: fixed;
  top: 20vh;
  left: 3vw;
  z-index: 3;
  padding: 30px 5px 15px;
	border-radius: 10px;
}

.close-button {
  z-index: 4;
  position: fixed;
  right: 6vw;
  top: 19vh;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
}

.close-button:hover {
  cursor: pointer;
}

.close-button-text {
  margin: 0;
}

.close-button-symbol {
  font-size: 30px;
  margin-left: 5px;
}

.caption {
  margin: 10px 10px 0 10px;
}

.ig-icon-small {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.ig-info-small {
  font-family: Parisienne;
  font-size: 24px;
}

.product-image {
  width: 100px;
  height: 100px;
  margin: 10px;
}

.product-image:hover {
  cursor: pointer;
  opacity: 0.8;
}

.product-image-frame {
  display: inline;
  padding: 0;
}

.enlarged-image {
  max-width: 80vw;
  border: 1px solid black;
}

.product-info-text {
  font-size: 12px;
  font-weight: bold;
}

.custom-font {
  color:rgb(17, 140, 202);
}

.gold-font {
  color: rgb(231, 197, 2);
}

.gallery-info-text {
	font-size: 14px;
	margin-top: 20px;
	color: #aaa;
}

@media (orientation: landscape) {
  #product-info {
    width: 380px;
  }

  .modal-frame {
    top: 6vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .close-button {
    top: 6vh;
  }

  .enlarged-image {
    max-height: 80vh;
  }
}

@media (min-width: 375px) {
  .product-image {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 667px) 
and (orientation: landscape) {
  .product-image {
    width: 140px;
    height: 140px;
  }
}

@media (min-width: 414px) {
  .product-image {
    width: 140px;
    height: 140px;
  }
}

@media (min-width: 736px) 
and (orientation: landscape) {
  .product-image {
    width: 160px;
    height: 160px;
  }
}

@media (min-width: 768px) {
  .product-image {
    width: 160px;
    height: 160px;
  }

  #product-info {
    width: 480px;
  }
}

@media (min-width: 812px) 
and (orientation: landscape) {
  .product-image {
    width: 180px;
    height: 180px;
  }
}
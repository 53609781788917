@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700);
@import url(https://fonts.googleapis.com/css?family=Parisienne);
@import url(https://fonts.googleapis.com/css?family=Lora);
nav {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 99;
}

#nav-bar {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
	align-items: flex-end;
  margin-bottom: 20px;
	padding-bottom: 6px;
}

#nav-list {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  width: 90%;
  padding: 0;
  margin: 0;
}

#nav-logo-link {
	margin-right: auto;
	padding-left: 16px;
}

.nav-list-item {
  margin: 0 10px;
}

.nav-link:link {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.nav-link:hover {
  color: lightblue;
}

.hidden {
  display: none;
}

.main-nav-logo:hover {
  color: lightblue;
}

.platina-design-logo-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.platina-design-logo-icon:hover {
  opacity: .6;
}

.platina-design-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
header {
  text-align: center;
}

h1 {
  font-family: Playfair Display;
  font-size: 30px;
  margin-bottom: 0;
  margin-top: 0;
}

h3 {
  font-size: 14px;
  font-weight: normal;
	color: rgb(55, 54, 54);
}

#main-header:hover {
  color: lightblue;
}

#platina-design-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

#platina-design-icon:hover {
  opacity: .6;
}

#platina-design-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
	h1 {
		font-size: 22px;
	}
	
	#platina-design-header {
		margin-top: 20px;
	}
}

@media (min-width: 1024px) {
	h1 {
		font-size: 30px;
	}
}

@media (min-width: 1200px) {
	h1 {
		font-size: 40px;
	}
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination-button {
  border: 1px solid lightgrey;
  font-size: 12px;
	padding: 6px;
	margin: 0 2px;
	border-radius: 10px;
	background: #e6e6e6;
}

.pagination-button:hover {
  cursor: pointer;
  background: grey;
}

.pagination li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.active button {
  border: none;
  background: none;
}

.active button:hover {
  cursor: default;
}

#homepage-products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-gallery-frame {
  width: 80vw;
  padding: 10px;
}

.image-gallery-frame p {
	font-size: 14px;
	margin-top: 20px;
	color: #aaa;
}

.product-details {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}

.product-info .product-info-text {
	font-size: 16px;
	padding: 0 20px;
}
h2 {
  font-size: 16px;
}

a {
  color: black;
}

a.fb-link {
	text-decoration: none;
}

main a:hover {
	color: #3e9cbb;
}

address {
  text-align: center;
  font-size: 14px;
}

address a {
	text-decoration: none;
}

#home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#main-content {
  background: url(/static/media/home.1b0eb939.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
}

#hours-info,
.hours-info {
  text-align: center;
  padding-top: 10px;
}

.hours-info {
  margin-bottom: 125px;
}

#location-map {
  height: 200px;
  width: 250px;
  border: 1px solid black;
  margin-top: 20px;
}

#location-map:hover {
  cursor: pointer;
  opacity: .8;
}

#location-header {
  margin-top: 40px;
  font-size: 20px;
}

#map-footnote,
.map-footnote {
  margin-top: 0;
}

.ig-info {
  font-family: Parisienne;
  font-size: 24px;
}

.fb-info {
  margin: 10px 0 20px;
}

.ig-icon {
  width: 25px;
  margin-right: 5px;
}

address .ig-info {
	margin: 40px 0 20px 0;
}

.fb-icon {
  width: 20px;
  margin-right: 5px;
}

.social-media-link {
  display: flex;
  align-items: center;
	justify-content: center;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.contact-numbers {
  margin-top: 40px;
}

.home-top-container {
	display: flex;
	flex-direction: column-reverse;
}

.home-top-right-container {
	display: flex;
	flex-direction: column;
}

.homepage-address {
	text-align: center;
	display: none;
}

@media (min-width: 370px) {
  #main-content {
    background-size: contain;
  }
}

@media (min-width: 375px) {
  #main-content {
    width: 340px;
    height: 340px;
  }

  .hours-info {
    padding-top: 15px;
    margin-bottom: 155px;
  }

  #homepage-address {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  h2 {
		font-size: 17px;
	}
	
	#home-section {
    margin-top: 10px;
  }

  #main-content {
    width: 50vw;
    height: 50vw;
		max-height: 90vh;
  }

  .hours-info {
    padding-top: 25px;
    margin-bottom: 200px;
  }

  #location-map {
    height: 200px;
    width: 250px;
  }

	.home-top-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	.home-top-right-container {
		width: 30vw;
		margin-left: 30px;
	}

	.homepage-address {
		display: block;
	}

	#main-content .ig-info {
		margin-top: auto;
		font-size: 32px
	}
	
	#main-content .fb-info {
		margin: 4px 0 60px;
	}
}

@media (min-width: 1024px) {
  h2 {
		font-size: 24px;
	}
	
	#home-section {
    margin-top: 10px;
  }

  #main-content {
    width: 50vw;
    height: 50vw;
		max-height: 90vh;
  }

  .hours-info {
    padding-top: 25px;
    margin-bottom: 200px;
  }

  #location-map {
    height: 250px;
    width: 300px;
  }

	.home-top-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	.home-top-right-container {
		width: 30vw;
	}

	.homepage-address {
		display: block;
	}

	#main-content .ig-info {
		margin-top: auto;
		font-size: 40px
	}
	
	#main-content .fb-info {
		margin: 20px 0 60px;
	}
}

@media (orientation: landscape) and (max-height: 400px) {
	.hours-info {
		margin-bottom: 144px;
	}
	
	.hours-info h2 {
		margin-top: 0px;
	}
}

/* @media (min-width: 1024px) {
  #home-section {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 70vw;
  }

  #homepage-address {
    align-self: center;
    margin-top: 0;
    margin-bottom: 20px;
  }

  #location-header {
    margin-top: 10px;
  }
} */

#about-text {
  text-align: justify;
  margin: 40px 40px 30px;
  font-size: 14px;
  line-height: 30px;
}

@media (min-width: 568px) and (orientation: landscape) {
  #about-text {
    width: 370px;
  }
}

@media (min-width: 768px) {
  #about-text {
    width: 450px;
  }
}
#products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#product-details {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}

#image-gallery-frame {
  width: 80vw;
  padding: 10px;
}

#product-info {
  width: 280px;
  margin-bottom: 15px;
}

.modal-frame {
  width: 90vw;
  max-height: 90vh;
  background: #f0f0f0;
  border: 1px solid #d9d7d7;
  position: fixed;
  top: 20vh;
  left: 3vw;
  z-index: 3;
  padding: 30px 5px 15px;
	border-radius: 10px;
}

.close-button {
  z-index: 4;
  position: fixed;
  right: 6vw;
  top: 19vh;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
}

.close-button:hover {
  cursor: pointer;
}

.close-button-text {
  margin: 0;
}

.close-button-symbol {
  font-size: 30px;
  margin-left: 5px;
}

.caption {
  margin: 10px 10px 0 10px;
}

.ig-icon-small {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.ig-info-small {
  font-family: Parisienne;
  font-size: 24px;
}

.product-image {
  width: 100px;
  height: 100px;
  margin: 10px;
}

.product-image:hover {
  cursor: pointer;
  opacity: 0.8;
}

.product-image-frame {
  display: inline;
  padding: 0;
}

.enlarged-image {
  max-width: 80vw;
  border: 1px solid black;
}

.product-info-text {
  font-size: 12px;
  font-weight: bold;
}

.custom-font {
  color:rgb(17, 140, 202);
}

.gold-font {
  color: rgb(231, 197, 2);
}

.gallery-info-text {
	font-size: 14px;
	margin-top: 20px;
	color: #aaa;
}

@media (orientation: landscape) {
  #product-info {
    width: 380px;
  }

  .modal-frame {
    top: 6vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .close-button {
    top: 6vh;
  }

  .enlarged-image {
    max-height: 80vh;
  }
}

@media (min-width: 375px) {
  .product-image {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 667px) 
and (orientation: landscape) {
  .product-image {
    width: 140px;
    height: 140px;
  }
}

@media (min-width: 414px) {
  .product-image {
    width: 140px;
    height: 140px;
  }
}

@media (min-width: 736px) 
and (orientation: landscape) {
  .product-image {
    width: 160px;
    height: 160px;
  }
}

@media (min-width: 768px) {
  .product-image {
    width: 160px;
    height: 160px;
  }

  #product-info {
    width: 480px;
  }
}

@media (min-width: 812px) 
and (orientation: landscape) {
  .product-image {
    width: 180px;
    height: 180px;
  }
}
#contact-section {
  text-align: center;
}

#contact-description {
  margin: 30px 0;
}

#contact-location-header,
#contact-hours {
  margin-top: 40px;
}

#contact-email {
  margin-top: 50px;
}

.contact-text {
  font-size: 12px;
}

footer {
  text-align: center;
  margin-top: 100px;
  border-top: 1px solid black;
  height: 60px;
}

#copyright-text {
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: 15px;
}

#credit-text {
  margin-top: 0;
	display: flex;
	flex-direction: column;
}

.small-text {
  font-size: 10px;
	margin: 4px 0;
}

.footer-spacer {
	width: 100%;
	height: 60px;
}

.mobile-footer-nav {
	width: 100vw;
	padding: 14px 0 4px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 9;
	box-shadow: 0px 0px 18px -12px #000;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.mobile-footer-nav a {
	width: 20%;
}

.footer-nav-icon {
	width: 25px;
	height: 25px;
}

.footer-nav-icon::not(.ping-icon) {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.footer-email-icon {
	width: 32px;
	height: 32px;
	position: relative;
	bottom: 4px;
}

@media (min-width: 480px) 
and (max-height: 600px) 
and (orientation: landscape) {
	.footer-nav-icon {
			width: 20px;
			height: 20px;
	}
	
	.footer-email-icon {
			width: 27px;
			height: 27px;
	}

	.mobile-footer-nav {
			padding-top: 14px;
			padding-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.mobile-footer-nav {
			position: static;
	}

	.footer-spacer {
		height: 10px;
	}
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a:hover {
  color: blue;
}

.no-underline {
  text-decoration: none;
}

.invisible-text {
	height: 1px;
	width: 1px;
	color: transparent;
	background: transparent;
}

@media (min-height: 700px) and (orientation: landscape) {
  #container {
    margin-bottom: 180px;
  }
}

@media (min-height: 768px) and (orientation: portrait) {
  #container {
    margin-bottom: 200px;
  }
}

@supports (-ms-ime-align: auto) {
  /* Microsoft EdgeV13&14 CSS styles go here */
  #nav-list {
    justify-content: space-around; /* adjust for no space-evenly */
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10 & IE11 CSS styles go here */
  #nav-list {
    justify-content: space-around; /* adjust for no space-evenly */
  }
}

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  margin: 0;
  font-family: Lora;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root,
.app,
#container {
	background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


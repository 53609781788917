#about-text {
  text-align: justify;
  margin: 40px 40px 30px;
  font-size: 14px;
  line-height: 30px;
}

@media (min-width: 568px) and (orientation: landscape) {
  #about-text {
    width: 370px;
  }
}

@media (min-width: 768px) {
  #about-text {
    width: 450px;
  }
}
nav {
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 99;
}

#nav-bar {
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
	align-items: flex-end;
  margin-bottom: 20px;
	padding-bottom: 6px;
}

#nav-list {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  width: 90%;
  padding: 0;
  margin: 0;
}

#nav-logo-link {
	margin-right: auto;
	padding-left: 16px;
}

.nav-list-item {
  margin: 0 10px;
}

.nav-link:link {
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.nav-link:hover {
  color: lightblue;
}

.hidden {
  display: none;
}

.main-nav-logo:hover {
  color: lightblue;
}

.platina-design-logo-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.platina-design-logo-icon:hover {
  opacity: .6;
}

.platina-design-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
@import url('https://fonts.googleapis.com/css?family=Lora');

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  margin: 0;
  font-family: Lora;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root,
.app,
#container {
	background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  text-align: center;
  margin-top: 100px;
  border-top: 1px solid black;
  height: 60px;
}

#copyright-text {
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: 15px;
}

#credit-text {
  margin-top: 0;
	display: flex;
	flex-direction: column;
}

.small-text {
  font-size: 10px;
	margin: 4px 0;
}

.footer-spacer {
	width: 100%;
	height: 60px;
}

.mobile-footer-nav {
	width: 100vw;
	padding: 14px 0 4px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 9;
	box-shadow: 0px 0px 18px -12px #000;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.mobile-footer-nav a {
	width: 20%;
}

.footer-nav-icon {
	width: 25px;
	height: 25px;
}

.footer-nav-icon::not(.ping-icon) {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.footer-email-icon {
	width: 32px;
	height: 32px;
	position: relative;
	bottom: 4px;
}

@media (min-width: 480px) 
and (max-height: 600px) 
and (orientation: landscape) {
	.footer-nav-icon {
			width: 20px;
			height: 20px;
	}
	
	.footer-email-icon {
			width: 27px;
			height: 27px;
	}

	.mobile-footer-nav {
			padding-top: 14px;
			padding-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.mobile-footer-nav {
			position: static;
	}

	.footer-spacer {
		height: 10px;
	}
}
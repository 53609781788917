@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700');

header {
  text-align: center;
}

h1 {
  font-family: Playfair Display;
  font-size: 30px;
  margin-bottom: 0;
  margin-top: 0;
}

h3 {
  font-size: 14px;
  font-weight: normal;
	color: rgb(55, 54, 54);
}

#main-header:hover {
  color: lightblue;
}

#platina-design-icon {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

#platina-design-icon:hover {
  opacity: .6;
}

#platina-design-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
	h1 {
		font-size: 22px;
	}
	
	#platina-design-header {
		margin-top: 20px;
	}
}

@media (min-width: 1024px) {
	h1 {
		font-size: 30px;
	}
}

@media (min-width: 1200px) {
	h1 {
		font-size: 40px;
	}
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.pagination-button {
  border: 1px solid lightgrey;
  font-size: 12px;
	padding: 6px;
	margin: 0 2px;
	border-radius: 10px;
	background: #e6e6e6;
}

.pagination-button:hover {
  cursor: pointer;
  background: grey;
}

.pagination li:first-child a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination li:last-child a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.active button {
  border: none;
  background: none;
}

.active button:hover {
  cursor: default;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a:hover {
  color: blue;
}

.no-underline {
  text-decoration: none;
}

.invisible-text {
	height: 1px;
	width: 1px;
	color: transparent;
	background: transparent;
}

@media (min-height: 700px) and (orientation: landscape) {
  #container {
    margin-bottom: 180px;
  }
}

@media (min-height: 768px) and (orientation: portrait) {
  #container {
    margin-bottom: 200px;
  }
}

@supports (-ms-ime-align: auto) {
  /* Microsoft EdgeV13&14 CSS styles go here */
  #nav-list {
    justify-content: space-around; /* adjust for no space-evenly */
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10 & IE11 CSS styles go here */
  #nav-list {
    justify-content: space-around; /* adjust for no space-evenly */
  }
}

@import url('https://fonts.googleapis.com/css?family=Parisienne');

h2 {
  font-size: 16px;
}

a {
  color: black;
}

a.fb-link {
	text-decoration: none;
}

main a:hover {
	color: #3e9cbb;
}

address {
  text-align: center;
  font-size: 14px;
}

address a {
	text-decoration: none;
}

#home-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#main-content {
  background: url('../../img/home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
}

#hours-info,
.hours-info {
  text-align: center;
  padding-top: 10px;
}

.hours-info {
  margin-bottom: 125px;
}

#location-map {
  height: 200px;
  width: 250px;
  border: 1px solid black;
  margin-top: 20px;
}

#location-map:hover {
  cursor: pointer;
  opacity: .8;
}

#location-header {
  margin-top: 40px;
  font-size: 20px;
}

#map-footnote,
.map-footnote {
  margin-top: 0;
}

.ig-info {
  font-family: Parisienne;
  font-size: 24px;
}

.fb-info {
  margin: 10px 0 20px;
}

.ig-icon {
  width: 25px;
  margin-right: 5px;
}

address .ig-info {
	margin: 40px 0 20px 0;
}

.fb-icon {
  width: 20px;
  margin-right: 5px;
}

.social-media-link {
  display: flex;
  align-items: center;
	justify-content: center;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.contact-numbers {
  margin-top: 40px;
}

.home-top-container {
	display: flex;
	flex-direction: column-reverse;
}

.home-top-right-container {
	display: flex;
	flex-direction: column;
}

.homepage-address {
	text-align: center;
	display: none;
}

@media (min-width: 370px) {
  #main-content {
    background-size: contain;
  }
}

@media (min-width: 375px) {
  #main-content {
    width: 340px;
    height: 340px;
  }

  .hours-info {
    padding-top: 15px;
    margin-bottom: 155px;
  }

  #homepage-address {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  h2 {
		font-size: 17px;
	}
	
	#home-section {
    margin-top: 10px;
  }

  #main-content {
    width: 50vw;
    height: 50vw;
		max-height: 90vh;
  }

  .hours-info {
    padding-top: 25px;
    margin-bottom: 200px;
  }

  #location-map {
    height: 200px;
    width: 250px;
  }

	.home-top-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	.home-top-right-container {
		width: 30vw;
		margin-left: 30px;
	}

	.homepage-address {
		display: block;
	}

	#main-content .ig-info {
		margin-top: auto;
		font-size: 32px
	}
	
	#main-content .fb-info {
		margin: 4px 0 60px;
	}
}

@media (min-width: 1024px) {
  h2 {
		font-size: 24px;
	}
	
	#home-section {
    margin-top: 10px;
  }

  #main-content {
    width: 50vw;
    height: 50vw;
		max-height: 90vh;
  }

  .hours-info {
    padding-top: 25px;
    margin-bottom: 200px;
  }

  #location-map {
    height: 250px;
    width: 300px;
  }

	.home-top-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	.home-top-right-container {
		width: 30vw;
	}

	.homepage-address {
		display: block;
	}

	#main-content .ig-info {
		margin-top: auto;
		font-size: 40px
	}
	
	#main-content .fb-info {
		margin: 20px 0 60px;
	}
}

@media (orientation: landscape) and (max-height: 400px) {
	.hours-info {
		margin-bottom: 144px;
	}
	
	.hours-info h2 {
		margin-top: 0px;
	}
}

/* @media (min-width: 1024px) {
  #home-section {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 70vw;
  }

  #homepage-address {
    align-self: center;
    margin-top: 0;
    margin-bottom: 20px;
  }

  #location-header {
    margin-top: 10px;
  }
} */
